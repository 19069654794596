<template>
  <div style="text-align: right">
    <button
      class="btn btn-xs btn-info m-b-5"
      :title="$t('restaurant.create_new_category')"
      v-if="searchedCategory !== ''"
      @click="createCategory"
    >
      <i class="fa fa-plus"></i>
      {{ $t('restaurant.create_new_category') }} <strong>{{ searchedCategory }}</strong>
    </button>
  </div>
</template>

<script>
import CoreApi from '../api/core'
import NotifyService from '../services/NotifyService'

export default {
  name: 'CreateCuisineCategoryButton',
  props: {
    searchedCategory: {
      type: String
    }
  },
  methods: {
    createCategory () {
      CoreApi().post('/cuisineCategory', { title: this.searchedCategory })
        .then((response) => {
          NotifyService.setSuccessMessage(this.$t('restaurant.create_new_category_message'))
          this.$emit('set-category', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
