<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="display">
                <button
                  v-if="isSaveButtonVisible"
                  type="button"
                  class="btn btn-success"
                  :disabled="saving"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('buttons.save') }}
                </button>
                <app-button-delete-with-usages
                  v-if="mode === 'update'"
                  @deleteRecord="deleteRestaurant"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isDeleteButtonVisible"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="restaurant_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <section v-if="display" class="content form-horizontal">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-body">
              <input type="hidden" v-model="restaurant.id">
              <app-input
                v-model="restaurant.title"
                @blur="$v.restaurant.title.$touch()"
                :error="$v.restaurant.title.$error"
                id="restaurant_title"
                :label="$t('restaurant.title')"
                :required="true"
              >
              </app-input>
              <app-textarea
                v-model="restaurant.description"
                id="restaurant_description"
                :label="$t('restaurant.description')"
                :maxlength="1024"
                @blur="$v.restaurant.description.$touch()"
                :error="$v.restaurant.description.$error"
              >
              </app-textarea>
              <app-input
                v-model="restaurant.name"
                @blur="$v.restaurant.name.$touch()"
                :error="$v.restaurant.name.$error"
                id="restaurant_name"
                :label="$t('restaurant.name')"
                :required="true"
              >
              </app-input>
              <app-input
                v-model="restaurant.yearOfReview"
                @blur="$v.restaurant.yearOfReview.$touch()"
                :error="$v.restaurant.yearOfReview.$error"
                id="restaurant_yearOfReview"
                :label="$t('restaurant.yearOfReview')"
                :required="true"
                type="number"
                :min="1900"
              >
              </app-input>
              <app-input
                v-model="restaurant.phone"
                @blur="$v.restaurant.phone.$touch()"
                :error="$v.restaurant.phone.$error"
                id="restaurant_phone"
                :label="$t('restaurant.phone')"
              >
              </app-input>
              <app-input
                v-model="restaurant.website"
                @blur="$v.restaurant.website.$touch()"
                :error="$v.restaurant.website.$error"
                id="restaurant_website"
                :label="$t('restaurant.website')"
              >
              </app-input>
              <app-input
                v-model="restaurant.chef"
                @blur="$v.restaurant.chef.$touch()"
                :error="$v.restaurant.chef.$error"
                id="restaurant_chef"
                :label="$t('restaurant.chef')"
              >
              </app-input>
              <app-input
                v-model="restaurant.openingHours"
                @blur="$v.restaurant.openingHours.$touch()"
                :error="$v.restaurant.openingHours.$error"
                id="restaurant_openingHours"
                :label="$t('restaurant.openingHours')"
              >
              </app-input>
              <div class="form-group">
                <div class="row">
                  <div class="col-lg-5">
                    <label>{{ $t('restaurant.cuisineCategories') }}</label>
                  </div>
                  <div class="col-lg-7">
                    <app-create-cuisine-category-button
                      v-if="searchedCuisineCategory"
                      :searchedCategory="searchedCuisineCategory"
                      @set-category="addCuisineCategory"
                    >
                    </app-create-cuisine-category-button>
                  </div>
                </div>
                <app-multi-select
                  v-model="restaurant.expanded.cuisineCategories"
                  :options="cuisineCategories"
                  label="title"
                  track-by="id"
                  :preselect-first="true"
                  :loading="isLoadingCuisineCategories"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="10"
                  :max-height="600"
                  :show-no-results="false"
                  open-direction="bottom"
                  @search-change="findCuisineCategory"
                  id="restaurant_cuisine_category"
                >
                </app-multi-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card card-body">
              <div class="form-group">
                <label :class="{'error': $v.restaurant.address.$error}">{{ $t('restaurant.address') }} <span
                  class="required">*</span></label><br>
                <app-address
                  @set-address="setAddress"
                  @delete-address="deleteAddress"
                  :addressId="this.restaurant.address"
                >
                </app-address>
              </div>
            </div>
            <div class="card card-body">
              <app-select
                v-model="restaurant.priceLevel"
                :options="restaurantHotelPriceLevelValues"
                id="restaurant_rating"
                :label="$t('restaurant.priceLevel')"
                :noEmptyValue="true"
                :required="true"
                optionValue="value"
              >
              </app-select>
              <app-select
                v-model="restaurant.rating"
                :options="restaurantHotelRatingValues"
                id="restaurant_rating"
                :label="$t('restaurant.rating')"
                :noEmptyValue="true"
                :required="true"
                optionValue="value"
              >
              </app-select>
              <div class="form-group">
                <label>{{ $t('restaurant.image') }}</label><br>
                <app-media-select-button
                  @set-media="setImage"
                >
                </app-media-select-button>
                <app-media-upload-button
                  @set-media="setImage"
                >
                </app-media-upload-button>
              </div>
              <div class="form-group">
                <app-media-editable
                  v-if="restaurantImage"
                  :media="restaurantImage"
                  :width="360"
                  :height="202"
                  @set-media="setImage"
                  @remove-media="removeImage"
                >
                </app-media-editable>
              </div>
              <table class="test-restaurant pros-cons">
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('restaurant.pros') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(pro, proIndex) in restaurant.prosCons.pros" :key="`item-${proIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="restaurant.prosCons.pros[proIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removePro($event, proIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddProsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addPro()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <th colspan="5">
                    <label>
                      <strong>{{ $t('restaurant.cons') }}:</strong>
                    </label>
                  </th>
                </tr>
                <tr v-for="(con, conIndex) in restaurant.prosCons.cons" :key="`item-${conIndex}`">
                  <td>
                    <app-advanced-input
                      v-model="restaurant.prosCons.cons[conIndex]"
                      showButtons
                      :buttons="inputDeleteButtons"
                      @input-button-clicked="removeCon($event, conIndex)"
                    >
                    </app-advanced-input>
                  </td>
                </tr>
                <tr v-if="showAddConsButton" class="button-align-center">
                  <td>
                    <button class="btn btn-success" @click="addCon()"><i class="fas fa-plus"></i>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </section>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
      <app-preloader-full v-if="saving"></app-preloader-full>
    </template>
  </app-module-view>
</template>

<script>
import { integer, maxLength, maxValue, minLength, minValue, required } from 'vuelidate/lib/validators'
import AdvancedInput from '../../components/form/inputs/InputAdvanced'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import Textarea from '../../components/form/Textarea'
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import PreloaderFull from '../../components/preloader/PreloaderFull'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import MediaEditable from '../../components/shared/MediaEditable'
import MediaSelectButton from '../../components/shared/MediaSelectButton'
import Address from '../../components/shared/Address'
import MediaUploadButton from '../../components/shared/MediaUploadButton'
import RestaurantModel from '../../model/RestaurantModel'
import NotifyService from '../../services/NotifyService'
import RestaurantHotelRatingMixin from '../../components/mixins/valueObject/RestaurantHotelRatingMixin'
import RestaurantHotelPriceLevelMixin from '../../components/mixins/valueObject/RestaurantHotelPriceLevelMixin'
import MultiSelect from '../../components/form/select/MultiSelect'
import CreateCuisineCategoryButton from '../../components/CreateCuisineCategoryButton'
import PermissionService from '@/services/PermissionService'

const MAX_PROS_CONS = 3

export default {
  name: 'RestaurantNewView',
  mixins: [RestaurantHotelRatingMixin, RestaurantHotelPriceLevelMixin],
  data () {
    return {
      dataLoaded: false,
      saving: false,
      restaurant: this._.cloneDeep(RestaurantModel),
      inputDeleteButtons: [{ class: 'btn btn-danger', html: '<i class="fas fa-trash"></i>' }],
      isLoadingCuisineCategories: false,
      cuisineCategories: [],
      searchedCuisineCategory: '',
      mode: 'create',
      usages: {},
      showAddProsButton: true,
      showAddConsButton: true,
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.ARTICLE_TEST_RESTAURANT_PERMISSIONS
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    display () {
      if (this.mode === 'create' || (this.mode === 'update' && this.dataLoaded)) {
        return true
      }
      return false
    },
    restaurantImage () {
      return this.$store.getters['restaurant/image']
    }
  },
  validations: {
    restaurant: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      yearOfReview: {
        integer,
        minValue: minValue(1900),
        maxValue: maxValue(3000)
      },
      openingHours: {
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      description: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      phone: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      website: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      chef: {
        minLength: minLength(1),
        maxLength: maxLength(1024)
      },
      priceLevel: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(5)
      },
      rating: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      address: {
        required,
        integer,
        minValue: minValue(1)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appAdvancedInput: AdvancedInput,
    appTextarea: Textarea,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonClose: ButtonClose,
    appAddress: Address,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable,
    appSelect: Select,
    appMultiSelect: MultiSelect,
    appPreloader: Preloader,
    appPreloaderFull: PreloaderFull,
    appCreateCuisineCategoryButton: CreateCuisineCategoryButton
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    findCuisineCategory (query) {
      this.isLoadingCuisineCategories = true
      this.searchedCuisineCategory = ''
      this.$store.dispatch('cuisineCategory/fetchByTitle', query)
        .then(() => {
          this.cuisineCategories = this.$store.getters['cuisineCategory/list']
          this.searchedCuisineCategory = query
          this.isLoadingCuisineCategories = false
        })
    },
    addCuisineCategory (category) {
      this.restaurant.expanded.cuisineCategories.push(category)
      this.searchedCuisineCategory = ''
    },
    addPro () {
      if (this.restaurant.prosCons.pros.length < MAX_PROS_CONS) {
        this.restaurant.prosCons.pros.push('')
        if (this.restaurant.prosCons.pros.length === MAX_PROS_CONS) {
          this.showAddProsButton = false
        }
      }
    },
    addCon () {
      if (this.restaurant.prosCons.cons.length < MAX_PROS_CONS) {
        this.restaurant.prosCons.cons.push('')
        if (this.restaurant.prosCons.cons.length === MAX_PROS_CONS) {
          this.showAddConsButton = false
        }
      }
    },
    removePro (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.restaurant.prosCons.pros.splice(dataIndex, 1)
      if (this.restaurant.prosCons.pros.length < MAX_PROS_CONS) {
        this.showAddProsButton = true
      }
    },
    removeCon (buttonIndex, dataIndex) {
      // we have only one button, so we dont need care of multiple buttons indexes
      this.restaurant.prosCons.cons.splice(dataIndex, 1)
      if (this.restaurant.prosCons.cons.length < MAX_PROS_CONS) {
        this.showAddConsButton = true
      }
    },
    checkProsConsButtons () {
      if (this.restaurant.prosCons.pros.length >= MAX_PROS_CONS) {
        this.showAddProsButton = false
      }
      if (this.restaurant.prosCons.cons.length >= MAX_PROS_CONS) {
        this.showAddConsButton = false
      }
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('restaurant/storeImage', media)
      })
    },
    setAddress (address) {
      this.restaurant.address = address.id
      this.restaurant.expanded.address = address
    },
    deleteAddress () {
      this.restaurant.address = null
      this.restaurant.expanded.address.id = null
      this.save()
    },
    removeImage () {
      this.$store.commit('restaurant/storeImage', null)
    },
    prepareRequest (restaurant) {
      const restaurantRequest = this._.cloneDeep(restaurant)
      restaurantRequest.image = null
      if (this.$store.getters['restaurant/image']) {
        restaurantRequest.image = this.$store.getters['restaurant/image'].id
      }
      restaurantRequest.prosCons.pros = restaurantRequest.prosCons.pros.filter(pro => pro.trim().length > 0)
      restaurantRequest.prosCons.cons = restaurantRequest.prosCons.cons.filter(con => con.trim().length > 0)
      if (this.restaurant.expanded.address.id) {
        restaurantRequest.address = this.restaurant.expanded.address.id
      }
      if (restaurant.expanded.cuisineCategories) {
        restaurantRequest.cuisineCategories = restaurant.expanded.cuisineCategories.map(record => record.id)
      }
      delete restaurantRequest.expanded
      return restaurantRequest
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.saving = true
        this.$store.dispatch('restaurant/create', this.prepareRequest(this.restaurant))
          .then(() => {
            this.saving = false
            if (this.$store.getters['restaurant/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.restaurant = this.$store.getters['restaurant/detail']
              this.$router.push('/articleTest/restaurant/' + this.restaurant.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['restaurant/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    checkUsages () {
      this.$store.commit('restaurant/setUsagesId', this.restaurant.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('restaurant/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['restaurant/usagesList']
          this.usages.totalCount = this.$store.getters['restaurant/usagesTotalCount']
          this.usages.page = this.$store.getters['restaurant/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('restaurant/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteRestaurant () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('restaurant/deleteRecord', this.restaurant)
        .then(() => {
          if (this.$store.getters['restaurant/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/restaurant')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['restaurant/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.$store.commit('restaurant/storeImage', null)
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
table {
  td {
    vertical-align: middle;

    &.unit {
      width: 15%;
      text-align: center;
      font-size: 0.85rem;
    }

    &:first-child {
      width: 30%;
    }

    input.form-control {
      text-align: right !important;
    }

    .form-group {
      margin-bottom: 5px;
    }
  }

  th {
    label:first-letter {
      text-transform: capitalize;
    }

    &:first-child {
      width: 30%;
    }
  }

  tr.button-align-center {
    text-align: center;
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>

<style lang="scss">
table.test-restaurant {
  td {
    input.form-control {
      text-align: right !important;
    }
  }

  &.pros-cons {
    td {
      input.form-control {
        text-align: left !important;
      }
    }
  }
}
</style>
